import React from "react";
import {Link} from "gatsby";

const styles = require("./VideoCourse.module.scss");

interface Props {
	id: string;
	name: string;
	chapter: number;
	icon: null | {
		asset: {
			url: string
		}
	}
}

const Chapter: React.FunctionComponent<Props> = ({id, name, chapter, icon}) => {

	const isReady = chapter === 1;

	if (!isReady) {
		return (
			<div className={`${styles.Chapter} ${styles.ComingSoon}`}>
				<div className={styles.Content}>
					<div>
						{icon && <img src={icon.asset.url}/>}
					</div>
					<div>
						<h4>Part {chapter} <span>Coming Soon</span></h4>
						<h2>{name}</h2>
					</div>
				</div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="18.95" height="31.606" viewBox="0 0 18.95 31.606">
						<path d="M2.182,31.606A2.182,2.182,0,0,1,.651,27.871L13.57,15.141.739,3.818A2.182,2.182,0,1,1,3.626.546L18.212,13.417a2.181,2.181,0,0,1,.088,3.189L3.713,30.979a2.178,2.178,0,0,1-1.531.627" transform="translate(0 0)" fill="#caced9"/>
					</svg>
				</div>
			</div>
		);
	}

	return (
		<Link to={`/getting-started/part-${chapter}`}>
			<div className={styles.Chapter}>
				<div className={styles.Content}>
					<div>
						{icon && <img src={icon.asset.url}/>}
					</div>
					<div>
						<h4>Part {chapter}</h4>
						<h2>{name}</h2>
					</div>
				</div>
				<div>
					<svg xmlns="http://www.w3.org/2000/svg" width="18.95" height="31.606" viewBox="0 0 18.95 31.606">
						<path d="M2.182,31.606A2.182,2.182,0,0,1,.651,27.871L13.57,15.141.739,3.818A2.182,2.182,0,1,1,3.626.546L18.212,13.417a2.181,2.181,0,0,1,.088,3.189L3.713,30.979a2.178,2.178,0,0,1-1.531.627" transform="translate(0 0)" fill="#caced9"/>
					</svg>
				</div>
			</div>
		</Link>
	);
};

export default Chapter;
