import React, {useEffect, useState} from "react";
import {graphql} from "gatsby";
import Chapters from "../../components/Layouts/VideoCourse/Chapters";

const styles = require('../../components/Layouts/VideoCourse/VideoCourse.module.scss');

interface Props {
}

function calculateHeight() {
    if (typeof window === "undefined") {
        return 0;
    }

    return window.innerHeight - 65;
}

const Index: React.FunctionComponent<Props> = ({data}) => {
    const chapters = data.allSanityVideoSeriesChapters.nodes;
    const [height, setHeight] = useState(calculateHeight());


    useEffect(() => {
        setHeight(calculateHeight());
    }, []);

    return (
        <div className={styles.Overview} style={{minHeight: `${height}px`}}>
            <div>
                <h1>Get Started with Prestavi</h1>
                <p>Learn everything that you need to know in order to make the most of Prestavi.</p>

                <Chapters chapters={chapters} />
            </div>

            <div style={{minHeight: `${height}px`, paddingBottom: "48px"}}></div>
        </div>
    );
};

export const query = graphql`
  {
    allSanityVideoSeriesChapters {
		nodes {
		  id
		  name
		  chapter
		  icon {
		  	asset {
		  		url
		  	}
		  }
		}
	  }
  }
`

export default Index;
