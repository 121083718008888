import React from "react";
import Chapter from './Chapter';

const styles = require("./VideoCourse.module.scss");

export interface Chapter {
    id: string;
    name: string;
    chapter: number;
    icon: null | {
        asset: {
            url: string
        }
    }
}

interface Props {
    chapters: Array<Chapter>;
}

const Chapters: React.FunctionComponent<Props> = ({chapters}) => {
	return (
		<div className={styles.Chapters}>
            {chapters.sort((a,b) => (a.chapter > b.chapter) ? 1 : ((b.chapter > a.chapter) ? -1 : 0))
                .map((chapter) => (
                <Chapter key={chapter.id} {...chapter} />
            ))}
		</div>
	);
};

export default Chapters;
